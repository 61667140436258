import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import {Link} from "react-router-dom";
import {renderInput} from "utils/forms/renderers";
import {email, onlyText, required} from "utils/forms/validators";
import Recaptcha from "components/Recaptcha/index";
import withRecaptcha from "utils/components/withRecaptcha/index";

export class SignUpForm extends Component {
  render(){
    const {errors, isLoading, refRecaptcha} = this.props;

    return(
        <form className="form form-auth" onSubmit={this.props.handleSubmit}>
          <div className="form-group">
            <label htmlFor="first_name">First Name <span className="required">*</span></label>
            <Field id="first_name"
                   name="first_name"
                   component={renderInput}
                   type="text"
                   className="form-control"
                   validate={[required, onlyText]}
                   placeholder="Type your first name"/>
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Last Name <span className="required">*</span></label>
            <Field id="last_name"
                   name="last_name"
                   component={renderInput}
                   type="text"
                   className="form-control"
                   validate={[required, onlyText]}
                   placeholder="Type your last name"/>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email <span className="required">*</span></label>
            <Field id="email"
                   name="email"
                   component={renderInput}
                   type="email"
                   className="form-control"
                   validate={[required, email]}
                   placeholder="Type your email address"/>
            {errors.email && errors.email.map((msj, index) => (
                <span className="help-block" key={index}>{msj}</span>
            ))}
          </div>
          <div className="form-group">
            <label htmlFor="password">Password <span className="required">*</span></label>
            <Field id="password"
                   name="password"
                   component={renderInput}
                   type="password"
                   className="form-control"
                   validate={[required]}
                   placeholder="Type your password"/>
            {errors.password && errors.password.map((msj, index) => (
                <span className="help-block" key={index}>{msj}</span>
            ))}
            {/*{errors.non_field_errors.map(error => (
                <span className="help-block">
                  {error}
                </span>
            ))}*/}
          </div>
          <Recaptcha
              afterCheckedRecaptcha={this.props.afterCheckedRecaptcha}
              refRecaptcha={refRecaptcha}
              onExpiredRecaptcha={this.props.onExpiredRecaptcha}/>
          <br/>
          <div className="form-group">
            {
              isLoading ? (
                  <input
                      type="submit"
                      value="CREATING..."
                      disabled={true}/>
              ) : (
                  <input
                      type="submit"
                      value="CREATE ACCOUNT"/>
              )
            }
          </div>
          <div className="options">
            <p className="text-center">
              <Link to="/auth/login"><span className="green">Log In&nbsp;</span></Link>
              <span className="vline"/>&nbsp;|&nbsp;
              <Link to="/auth/forgot-password"><span>&nbsp;Forgot Password?</span></Link>
            </p>
          </div>
        </form>
    )
  }
}

SignUpForm = reduxForm({
  form: 'authSignUpForm'
})(SignUpForm);

export default withRecaptcha(SignUpForm)