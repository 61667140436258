import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import vintageHistory from "services/browser-history/index";
import {getStoredCards} from "scenes/Store/scenes/Checkout/services/storage/index";
import {isAuthenticated} from "services/auth/index";
import AddCreditCardModalContainer
  from "scenes/MyAccount/scenes/CreditCardsInfo/components/AddCreditCardModal/container/index";
import PayPalInfo
  from "scenes/Store/scenes/Checkout/scenes/SelectPaymentMethod/components/CheckoutCreditCardForm/components/CheckoutPayPal/components/PayPalInfo";
import "./styles/CheckoutCreditCardForm.scss"
import CreditCardReduxFormContainer
  from "scenes/MyAccount/scenes/CreditCardsInfo/components/AddCreditCardModal/components/CreditCardForm/container";
import Address from "scenes/MyAccount/scenes/AddressBook/components/Address";
import CreditCard from "scenes/MyAccount/scenes/CreditCardsInfo/components/CreditCard";
import {
  CREDIT_CARD_PAYMENT_METHOD,
  PAYPAL_PAYMENT_METHOD
} from "scenes/Store/scenes/Checkout/scenes/SelectPaymentMethod/components/CheckoutCreditCardForm/constants";
import CreditCardInfo from "utils/components/CreditCardInfo";
import CreditCardListContainer
  from "scenes/Store/scenes/Checkout/scenes/SelectPaymentMethod/components/CheckoutCreditCardForm/components/CreditCardList/container";
import LoadingCassette from "utils/components/misc/LoadingCassette";
import CVVCodeReduxForm
  from "scenes/Store/scenes/Checkout/scenes/SelectPaymentMethod/components/CheckoutCreditCardForm/components/CVVCodeForm";
import {withRouter} from "react-router"


class CheckoutCreditCardForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: CREDIT_CARD_PAYMENT_METHOD,
      creditCardListIsOpen: false,
      selectedCreditCard: null,
      selectedBillingAddress: null,
      creditCardFormLoading: false,
      creditCardFormSuccess: false,
      creditCardFormErrors: {},
      addressFormErrors: []
    };

    this.handlePaymentMethodChange = paymentMethod => this._handlePaymentMethodChange.bind(this, paymentMethod);
    this.handleConfirmYourOrderButtonClick = this.handleConfirmYourOrderButtonClick.bind(this);
    this.onAfterCreated = this.onAfterCreated.bind(this);
    this.handleCreditCardFormSubmit = this.handleCreditCardFormSubmit.bind(this);
    this.handleEditSelectedCreditCard = this.handleEditSelectedCreditCard.bind(this);
    this.toggleCreditCardListIsOpen = this.toggleCreditCardListIsOpen.bind(this);
    this.handleUseThisCreditCardButtonClick = this.handleUseThisCreditCardButtonClick.bind(this);
    this.selectCheckoutCreditCardByDefault = this.selectCheckoutCreditCardByDefault.bind(this);
    this.handleCVVCodeFormSubmit = this.handleCVVCodeFormSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchCreditCards();
  }

  /**
   * Fetches credit cards.
   * @returns {AxiosPromise.<any>}
   */
  fetchCreditCards() {
    const promise = this.creditCardsPromise;

    promise.then(response => {
      const { creditCards } = this.props.myAccountReducer;
      const { selectedCreditCardId } = this.props.checkoutReducer;
      const responseCards = response?.data?.results || [];

      if (!creditCards || creditCards.length === 0) {
        this.props.receiveCreditCards(responseCards);
      }

      if (!selectedCreditCardId) {
        this.selectCheckoutCreditCardByDefault();
        return promise;
      }

      const selectedCreditCard =
          responseCards.find(card => card.id === selectedCreditCardId) ||
          null;

      if(selectedCreditCard) {
        this.setState({
          selectedCreditCard,
          selectedBillingAddress: selectedCreditCard.billing_address_data
        })
      }

    });

    return promise;
  }

  /**
   * Checks if credit cards are already in the store and returns a promise to fetch
   * them from the API if not.
   * @returns {AxiosPromise<any>}
   */
  get creditCardsPromise() {
    const {creditCards} = this.props.myAccountReducer;
    // CreditCards already fetched, no need to re fetch them
    if (!isAuthenticated() && creditCards && creditCards.length > 0)
      return Promise.resolve({data: {results: creditCards}});

    // Simulate promise returning empty array when user is not authenticated
    const promise = isAuthenticated() ?
        vintageAxios.get('/v2/management/customers/credit-cards/')
        :
        Promise.resolve({data: {results: getStoredCards()}});
    promise.then(response => this.props.receiveCreditCards(response.data.results));
    return promise;
  }

  /**
   * After created handler to AddCreditCard modal
   */
  onAfterCreated() {
    if (this.props.myAccountReducer.creditCards.length === 1) {
      // Just created an address, so the new one is the only one
      this.selectCheckoutCreditCardByDefault();
    }
  }

  /**
   * Select the credit card marked as default or the first in the list.
   */
  selectCheckoutCreditCardByDefault() {
    const {creditCards, addresses} = this.props.myAccountReducer;
    let selectedCreditCard;

    if (creditCards && creditCards.length > 0) {
      selectedCreditCard = creditCards[0];
      creditCards.forEach(creditCard => {
        if (creditCard.default === true) {
          selectedCreditCard = creditCard;
        }
      });
      this.props.selectCheckoutCreditCard(selectedCreditCard.id);
      this.props.selectCheckoutBillingAddress(selectedCreditCard.billing_address);
      this.setState({
        selectedCreditCard,
        selectedBillingAddress: selectedCreditCard.billing_address_data
      })
    } else
      this.props.selectCheckoutCreditCard(null);
  }

  /**
   * Handles payment method changes
   * @param paymentMethod
   * @private
   */
  _handlePaymentMethodChange(paymentMethod) {
    this.setState({paymentMethod});
    if (paymentMethod === PAYPAL_PAYMENT_METHOD)
      this.props.selectCheckoutCreditCard(0);
    else
      this.selectCheckoutCreditCardByDefault();
  }

  /**
   * Handles submit on the <form> to redirect to next step.
   */
  handleConfirmYourOrderButtonClick() {
    const {checkoutReducer} = this.props;

    if (checkoutReducer.selectedCreditCardId)
      this.props.submitCVVCodeForm();
    else
      this.props.submitCreditCardForm();
  }

  /**
   * Handler the submit of creditCardForm and calls saveAddress or SaveCreditCard methods based on address data in
   * the received data param.
   * @param data
   */
  handleCreditCardFormSubmit(data) {
    const {checkoutReducer} = this.props;
    this.setState({
      creditCardFormLoading: true
    }, () => {
      let creditCard = Object.assign({}, data);
      delete creditCard["address"];
      // The billing address is created. Is added when modal is open
      if (data["address"])
        this.saveAddress(data);
      else {
        creditCard.billing_address = checkoutReducer.selectedShippingAddressId;
        this.saveCreditCard(creditCard);
      }
    });
  }

  /**
   * Handle edit button to dispatch selectCreditCard action and open the CreditCardModal in Edit mode
   */
  handleEditSelectedCreditCard() {
    this.props.selectCreditCard(this.state.selectedCreditCard);
  }

  /**
   * Toggle creditCardListIsOpen state data
   */
  toggleCreditCardListIsOpen() {
    this.setState({creditCardListIsOpen: !this.state.creditCardListIsOpen})
  }

  /**
   * Handle "USE THIS CARD" button click of CreditCardList to set as default the selected credit card and calls
   * toggleCreditCardListIsOpen method
   */
  handleUseThisCreditCardButtonClick() {
    const {selectedCreditCard} = this.state;
    CreditCard.setCreditCardAsDefault(selectedCreditCard);
    this.toggleCreditCardListIsOpen()
  }

  /**
   * Handle CVVCodeForm submit to dispatch setCheckoutCVVCode action and calls nextCheckoutStep method
   * @param data
   */
  handleCVVCodeFormSubmit(data) {
    // Set Cvv code in the store
    if(data && data.ccv_code){
      this.props.setCheckoutCVVCode(data.ccv_code);
      this.nextCheckoutStep()
    }
  }

  /**
   * Creates or edits an address.
   * @param {Object} data
   * @returns {AxiosPromise<any>}
   */
  saveAddress(data) {
    Address.createOrUpdateAddress(data.address).then(response => {
      let creditCard = Object.assign({}, data);
      delete creditCard["address"]; // The billing address is created

      creditCard.billing_address = response.data.id;
      this.saveCreditCard(creditCard);
    });
  }

  /**
   * Creates or edits an creditCard.
   * @param {Object} data
   * @returns {AxiosPromise<any>}
   */
  saveCreditCard(data) {
    CreditCard.createOrUpdateCreditCard(data).then(response => {
      this.setState({
        creditCardFormLoading: false,
        creditCardFormSuccess: true,
      });
      this.props.selectCheckoutCreditCard(response.data.id);
      this.props.selectCheckoutBillingAddress(response.data.billing_address);
      this.props.setCheckoutCVVCode(data.verification_value);
      this.nextCheckoutStep()

    }).catch(error => {
      this.setState({
        creditCardFormLoading: false,
        creditCardFormSuccess: false,
        creditCardFormErrors: error.response.data
      });
    });
  }

  /**
   * If exists a selected credit card, redirect to order summary
   */
  nextCheckoutStep() {
    if (this.props.checkoutReducer.selectedCreditCardId)
      this.props.history.push({
        pathname: '/store/checkout/summary'
      });
  }


  render() {
    const {creditCards} = this.props.myAccountReducer;
    const {
      paymentMethod, creditCardListIsOpen, creditCardFormLoading,
      creditCardFormSuccess, creditCardFormErrors, addressFormErrors, selectedCreditCard, selectedBillingAddress
    } = this.state;
    const {checkoutReducer} = this.props;

    return (
        <div className="checkout-step checkout-step--payment-method">
          <div className="checkout-step__header">
            <h2>
              <span className="circle">3</span><span>Payment Method</span>
            </h2>
          </div>
          <div className="checkout-step__body">
            {
              (creditCards && creditCards.length > 0 && creditCardListIsOpen) ? (
                  <CreditCardListContainer
                      onUseThisCreditCardButtonClick={this.handleUseThisCreditCardButtonClick}
                      onAfterSelectCreditCard={this.selectCheckoutCreditCardByDefault}
                      creditCards={creditCards}
                      selectedCreditCard={selectedCreditCard}/>

              ) : (
                  <div>
                    {/*<div
                        className="checkout-radio radio">
                      <label
                          className="checkout-radio__label"
                          htmlFor={`creditCard1`}>
                        <input
                            type="radio"
                            name="creditCard"
                            id={`creditCard1`}
                            value={1}
                            onChange={this.handlePaymentMethodChange(PAYPAL_PAYMENT_METHOD)}
                            checked={paymentMethod === PAYPAL_PAYMENT_METHOD}/>
                        <PayPalInfo/>
                      </label>
                    </div>*/}
                    <div className="payment-method__credit-card">
                      <div
                          className="checkout-radio radio">
                        <label
                            className="checkout-radio__label"
                            htmlFor={`creditCard0`}>
                          <input
                              type="radio"
                              name="creditCard"
                              id={`creditCard0`}
                              value={0}
                              onChange={this.handlePaymentMethodChange(CREDIT_CARD_PAYMENT_METHOD)}
                              checked={paymentMethod === CREDIT_CARD_PAYMENT_METHOD}/>
                          <span className="bold">
                    Credit Card
                    </span>
                          <span className="credit-card--info">
                      <span className="icon credit-card-logo__amex"/>
                      <span className="icon credit-card-logo__visa"/>
                      <span className="icon credit-card-logo__master-card"/>
                      <span className="icon credit-card-logo__discover"/>
                    </span>
                        </label>
                      </div>
                    </div>
                    {
                      paymentMethod === CREDIT_CARD_PAYMENT_METHOD &&
                      <div>
                        {
                          ((!checkoutReducer.selectedCreditCardId && !creditCardListIsOpen && !creditCards) ||
                              (checkoutReducer.selectedCreditCardId && !selectedCreditCard)) &&
                          <LoadingCassette/>
                        }
                        {
                          (checkoutReducer.selectedCreditCardId && !creditCardListIsOpen) &&
                          <div className="row payment-method__credit-card--selected">
                            {
                              selectedCreditCard &&
                              <div>
                                <div className="col-md-12">
                                  <CreditCardInfo
                                      creditCard={selectedCreditCard}/>
                                  {
                                    selectedBillingAddress &&
                                    <p className="credit-card--selected__billing-address">
                                      <strong>Billing Address: </strong>
                                      {selectedBillingAddress.address_1}{selectedBillingAddress.address_2 && `, ${selectedBillingAddress.address_2}`}
                                      <br/>
                                      {selectedBillingAddress.city_name}, {selectedBillingAddress.region_name} {selectedBillingAddress.zip_code}
                                    </p>
                                  }
                                  <a
                                      onClick={this.handleEditSelectedCreditCard}
                                      className="credit-card--selected__edit">
                                    <i className="fa fa-pencil"/> Edit Address
                                  </a>
                                </div>
                                <div className="col-md-6 credit-card--selected__cvv-code">
                                  <CVVCodeReduxForm
                                      onSubmit={this.handleCVVCodeFormSubmit}/>
                                </div>
                                <div className="col-md-6 credit-card--selected__change">
                                  <a onClick={this.toggleCreditCardListIsOpen}>
                                    Change Credit Card
                                  </a>
                                </div>
                              </div>
                            }
                          </div>
                        }
                        {
                          (creditCards && creditCards.length === 0 && !creditCardListIsOpen) &&
                          <CreditCardReduxFormContainer
                              onSubmit={this.handleCreditCardFormSubmit}
                              isLoading={creditCardFormLoading}
                              success={creditCardFormSuccess}
                              errors={creditCardFormErrors}
                              addressErrors={addressFormErrors}
                              withBillingInfo={false}
                              withSubmitButtons={false}/>
                        }
                      </div>
                    }
                    <button
                        className="btn btn-block"
                        type="submit"
                        onClick={this.handleConfirmYourOrderButtonClick}>
                      CONFIRM YOUR ORDER
                    </button>
                  </div>

              )

            }
          </div>
          <AddCreditCardModalContainer onAfterCreated={this.onAfterCreated}/>
        </div>
    )
  }
}

export default withRouter(CheckoutCreditCardForm)
