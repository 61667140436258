import React from 'react';
import PropTypes from 'prop-types';
import "./styles/AddressInfo.scss";

const AddressInfo = ({address}) => {
    return (
        <span>
      <p className="address-info">
        <strong>{address.full_name}</strong><br/>
          {address.address_1}{`${address.address_2 ? ', ' + address.address_2 : ''}`}&nbsp;
      </p>
      <p>
        {address.city_name}, {address.state_code}&nbsp;
          {address.zip_code}
      </p>
    </span>

    )
};

AddressInfo.propTypes = {
  address: PropTypes.object.isRequired
};

export default AddressInfo;
