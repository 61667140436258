import * as types from '../actions/types';

export const initialState = {
  selectedShippingAddressId: null,
  selectedBillingAddressId: null,
  selectedCreditCardId: null,
  selectedCVVCode: null
};

export const checkoutReducer = (state=initialState, action) => {
  switch(action.type){
    case types.CHECKOUT_SHIPPING_ADDRESS_SELECT:
      return {
        ...state,
        selectedShippingAddressId: action.addressId
      };
    case types.CHECKOUT_BILLING_ADDRESS_SELECT:
      return {
        ...state,
        selectedBillingAddressId: action.addressId
      };
    case types.CHECKOUT_CREDIT_CARD_SELECT:
      return {
        ...state,
        selectedCreditCardId: action.creditCardId
      };
    case types.CHECKOUT_CVV_CODE_SET:
      return {
        ...state,
        selectedCVVCode: action.cvv_code
      };
    case types.EMPTY_CHECKOUT_INFORMATION:
      return {
        ...initialState
      };
    default:
      return state;
  }
};