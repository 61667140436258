import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {Link} from "react-router-dom";
import {renderInput} from "utils/forms/renderers";
import {email, required} from "utils/forms/validators";
import {AUTH} from "scenes/Auth/scenes/Login/components/LoginIsolated/constants/contexts";
import Recaptcha from "components/Recaptcha/index";
import withRecaptcha from "utils/components/withRecaptcha/index";

export class LoginForm extends Component {
  render() {
    const {isLoading, errors, context, refRecaptcha, checkedRecaptcha} = this.props

    return (
        <form className="form form-auth" onSubmit={this.props.handleSubmit}>
          <div className="form-group">
            {
              context === AUTH &&
              <label htmlFor="email">Email <span className="required">*</span></label>
            }
            <Field id="email"
                   name="email"
                   component={renderInput}
                   type="email"
                   className="form-control"
                   validate={[required, email]}
                   placeholder="Email address"/>
          </div>
          <div className="form-group">
            {
              context === AUTH &&
              <label htmlFor="password">Password <span className="required">*</span></label>
            }
            <Field id="password"
                   name="password"
                   component={renderInput}
                   type="password"
                   className="form-control"
                   validate={[required]}
                   placeholder="Password"
                   autoComplete="off"/>
            {errors.map((error, index) => (
                <span
                    key={`passwordError${index}`}
                    className="help-block">
                  {error}
                </span>
            ))}
          </div>
            <div>
              <Recaptcha
                  afterCheckedRecaptcha={this.props.afterCheckedRecaptcha}
                  refRecaptcha={refRecaptcha}
                  onExpiredRecaptcha={this.props.onExpiredRecaptcha}/>
              <br/>
            </div>
          <div className="form-group">
            {
              isLoading ? (
                  <input
                      type="submit"
                      value="LOGGING IN..."
                      disabled={true}/>
              ) : (
                  <input
                      type="submit"
                      value="LOGIN"
                      disabled={context === AUTH ? !checkedRecaptcha : false}/>
              )
            }
          </div>
          <div className="options">
            <p className="text-center">
              {
                context === AUTH ? (
                    <Link to="/auth/signup"><span className="green">Create an Account&nbsp;</span></Link>
                ) : (
                    <Link to="/auth/signup" onClick={this.props.onLinkClick}><span
                        className="green">Create an Account&nbsp;</span></Link>
                )
              }
              <span className="vline"/>&nbsp;|&nbsp;
              {
                context === AUTH ? (
                    <Link to="/auth/forgot-password"><span>&nbsp;Forgot Password?</span></Link>

                ) : (
                    <Link to="/auth/forgot-password"
                          onClick={this.props.onLinkClick}><span>&nbsp;Forgot Password?</span></Link>
                )
              }
            </p>
          </div>
        </form>
    )
  }
}

LoginForm = reduxForm({
  form: 'authLoginForm'
})(LoginForm);

export default withRecaptcha(LoginForm)