import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import AddressContainer from "scenes/MyAccount/scenes/AddressBook/components/Address/container/index";
import AddAddressModalContainer from "scenes/MyAccount/scenes/AddressBook/components/AddAddressModal/container/index";
import Breadcrumb from "utils/components/Breadcrumb/index";
import "./styles/AddressBook.scss";
import SEO from "components/SEO/index";

class AddressBook extends Component {
  constructor(props){
    super(props);

    this.handleAddClick = this.handleAddClick.bind(this);
  }

  /**
   * Fetch addresses from the API.
   * @returns {AxiosPromise<any>}
   */
  fetchAddresses(){
    const promise = vintageAxios.get('/v2/management/customers/addressess/');
    promise.then(response => {
      this.props.receiveAddresses(response.data.results);
    });
    return promise;
  }

  /**
   * Handles clicks on add address button.
   */
  handleAddClick(){
    const address = {}; // A new, empty address
    this.props.selectAddress(address);
  }

  componentDidMount(){
    this.fetchAddresses();
  }

  render(){
    const {addresses} = this.props.myAccountReducer;


    return(
        <div>
          <Breadcrumb
              crumbs={[
                {title: "My Account", to: "/my-account"},
                {title: "Address Book", to: "/my-account/address-book"},
              ]}/>
          <div className="container">
            <div className="wrapper addresses">
              <div className="address-book-header">
                <div className="filters">
                  <a
                      className="btn-custom"
                      onClick={this.handleAddClick}>
                    <span className="icon icon-plus"/>
                    <span>ADD</span>
                  </a>
                </div>
                <h2>
                  <span className="icon icon-address-book"/>
                  <span className="text">Address Book</span>
                </h2>
              </div>
              {
                addresses === null ? (
                    <LoadingCassette/>
                ) : (
                    addresses.length === 0 ? (
                        <div className="empty-message">
                          <h1>Your Address Book is Empty.</h1>
                          <p>Looks like you haven’t add any address yet.</p>
                          <div className="footer">
                            <div className="bg addresses"/>
                            <p>
                              <a
                                  onClick={this.handleAddClick}>
                                <span className="btn-custom">ADD AN ADDRESS</span>
                              </a>
                            </p>
                          </div>
                        </div>
                    ) : (
                        addresses.map(address =>
                            <AddressContainer
                                key={`addressBookAddress${address.id}`}
                                address={address}/>
                        )
                    )
                )
              }
              <AddAddressModalContainer/>
            </div>
          </div>
          <SEO url="my-account/address-book"/>
        </div>
    )
  }
}

export default AddressBook
