import React, {useState} from "react"
import {useHistory} from "react-router"
import {
  QUERY_PARAM_IN_STOCK,
  QUERY_PARAM_PICKUP_AVAILABLE, QUERY_PARAM_STORE
} from "scenes/Store/scenes/ProductsList/constants/query-params"
import useQueryParams from "hooks/useQueryParams"
import AvailabilityModal from "scenes/Store/scenes/ProductsList/components/AvailabilityModal"
import LocationName from "utils/components/LocationName"

export default function AvailabilityCheckboxes() {
  const history = useHistory()
  const queryParams = useQueryParams()
  const [showModal, setShowModal] = useState(false)
  const storeFromParams = queryParams.find(QUERY_PARAM_STORE)

  /**
   * Handles change event on the checkboxes
   * @param event {Object}
   */
  function handleChange(event) {
    const {checked} = event.target;
    let queryParam
    if (event.target.id === 'in-store-pickup')
      queryParam = QUERY_PARAM_PICKUP_AVAILABLE
    else
      queryParam = QUERY_PARAM_IN_STOCK
    const newParams = {
      [queryParam]: event.target.checked ? true : null
    }
    if (queryParam === QUERY_PARAM_PICKUP_AVAILABLE && !checked)
      newParams[QUERY_PARAM_STORE] = null
    queryParams.update(newParams)
    history.push({
      pathname: "/store/products",
      search: queryParams.asSearchString
    })
  }

  const handleStoresClick = event => {
    event.stopPropagation()
    event.preventDefault()
    setShowModal(true)
  }

  return (
      <>
        <label
            htmlFor="in-store-pickup"
            className="availability-checkbox">
          <input
              type="checkbox"
              id="in-store-pickup"
              onChange={handleChange}
              value="In Store Pickup"
              checked={queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE) !== undefined}/>
          <span className="text">
            &nbsp;&nbsp;In Store Pickup
            {
              queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE) !== undefined &&
              <span>
                &nbsp;at&nbsp;
                <strong
                    className="text-underline"
                    onClick={handleStoresClick}>
                  {
                    storeFromParams
                        ?
                        <LocationName id={storeFromParams}/>
                        :
                        'All Stores'
                  }
                </strong>
              </span>
            }
          </span>
        </label>
        <label
            htmlFor="online"
            className="availability-checkbox">
          <input
              type="checkbox"
              id="online"
              onChange={handleChange}
              value="Online"
              checked={queryParams.find(QUERY_PARAM_IN_STOCK) !== undefined}/>
          <span className="text">&nbsp;&nbsp;Online</span>
        </label>
        <AvailabilityModal
            show={showModal}
            onHide={() => setShowModal(false)}/>
      </>
  )
}