import React, {Component, createRef} from 'react';
import LoginForm from "scenes/Auth/scenes/Login/components/LoginForm/index";
import {login as authLogin} from "services/auth/index";
import {getCart, getCartSession, updateCartOwner} from "services/store/index";
import {AUTH, MAIN_MENU} from "scenes/Auth/scenes/Login/components/LoginIsolated/constants/contexts";
import PropTypes from "prop-types";
import AuthSocialButtons from "scenes/Auth/scenes/Login/components/AuthSocialButtons/index";

class LoginIsolated extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: []
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleAfterSocialSubmit = this.handleAfterSocialSubmit.bind(this);
    this.refRecaptcha = createRef();
  }

  /**
   * Merge anonymous cart with logged cart or retrieve user cart
   */
  convertCart() {
    let promise;
    if (getCartSession()) {
      promise = updateCartOwner();
    } else {
      promise = getCart()
    }
    promise.then(response => {
      this.props.receiveCart(response.data)
    });
  }

  /**
   * Performs the request to login the user. Stores user in redux store if success.
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  login(data) {
    const promise = authLogin(data.email, data.password, this.refRecaptcha.current.getValue());
    promise.then(response => {
      this.setState({
        loading: false
      });

      this.props.emptyAccountInformation();
      this.props.emptyCheckoutInformation();
      this.props.login(response.data);

      this.convertCart();

      if (this.props.context === MAIN_MENU)
        this.props.onLinkClick()
    });
    promise.catch(error => {
      let state = {
        loading: false
      };
			this.refRecaptcha.current.reset();
      if (error.response && error.response.status === 500)
        state['errors'] = ['A server error has occurred, please try again or contact support.'];
      else {
        const {non_field_errors} = error.response.data;
        if (non_field_errors)
          state['errors'] = non_field_errors;
      }
      this.setState(state);
    });
    return promise;
  }

  /**
   * Handles form submit.
   * @param {Object} data
   */
  handleFormSubmit(data) {
    return this.setState({
      loading: true
    }, () => {
      this.login(data);
    });
  }

  handleAfterSocialSubmit(response) {
    this.props.login(response.data);
    this.convertCart();

    if (this.props.context === MAIN_MENU)
      this.props.onLinkClick()
  }

  render() {
    const {loading, errors} = this.state;

    return (
        <div>
          <AuthSocialButtons
              onAfterSubmit={this.handleAfterSocialSubmit}/>
          <LoginForm
              onSubmit={this.handleFormSubmit}
              isLoading={loading}
              errors={errors}
              context={this.props.context}
              refRecaptcha={this.refRecaptcha}
              onLinkClick={this.props.onLinkClick}/>
        </div>
    )
  }
}

LoginIsolated.defaultProps = {
  context: AUTH
};

LoginIsolated.propTypes = {
  context: PropTypes.oneOf([AUTH, MAIN_MENU]),
  onLinkClick: PropTypes.func,
};

export default LoginIsolated;
