import React, {Component} from 'react';
import CartTotalsContainer from "scenes/Store/scenes/Cart/components/CartTotals/container/index";
import CartItemContainer from "scenes/Store/scenes/Cart/components/CartItem/container/index";
import {Link, Redirect, withRouter} from "react-router-dom";
import {placeOrder} from "services/store";
import {isAuthenticated} from "services/auth";
import VintageAlert from "utils/components/VintageAlert/index";
import {FROM} from "scenes/Store/scenes/ProductsList/constants/query-params";
import {PaypalButton} from "scenes/Store/scenes/Checkout/scenes/SelectPaymentMethod/components/CheckoutCreditCardForm/components/PayPalButton";
import SEO from "components/SEO/index";
import AddressInfo from "utils/components/AddressInfo";
import CreditCardInfo from "utils/components/CreditCardInfo";
import "./styles/Summary.scss";
import PayPalInfo
  from "scenes/Store/scenes/Checkout/scenes/SelectPaymentMethod/components/CheckoutCreditCardForm/components/CheckoutPayPal/components/PayPalInfo";
import CartCouponContainer from "scenes/Store/scenes/Cart/components/CartCoupon/container";

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      placingOrder: false,
      nonFieldErrors: []
    };

    this.placeOrder = this.placeOrder.bind(this);
    this.handlePlaceOrderClick = this.handlePlaceOrderClick.bind(this);
    this.completedOrder = this.completedOrder.bind(this);
  }

  /**
   * Place the order through the API.
   * @returns {AxiosPromise}
   */
  placeOrder() {
    const {checkoutReducer} = this.props;
    this.setState({
      placingOrder: true
    });
    const promise = placeOrder({
      billingAddressId: checkoutReducer.selectedBillingAddressId,
      shippingAddressId: checkoutReducer.selectedShippingAddressId,
      creditCardId: checkoutReducer.selectedCreditCardId,
      cvv: checkoutReducer.selectedCVVCode
    });
    promise.then(response => {
      this.setState({
        placingOrder: false
      }, () => {
        this.props.history.push({
          pathname: `/store/checkout/${response.data.uuid}/success`
        });
      });
    });
    promise.catch(error => {
      const {non_field_errors} = error.response.data
      this.setState({
        placingOrder: false,
        nonFieldErrors: non_field_errors
      })
    })
    return promise;
  }

  /**
   * Indicates that the order has been completed.
   * @param {String} uuid
   */
  completedOrder(uuid) {
    this.setState({
      placingOrder: false
    }, () => {
      this.props.history.push({
        pathname: `/store/checkout/${uuid}/success`
      });
    });
  }

  /**
   * Handles clicks on the place order button, modifies the state to make
   * the button "loading" and calls the place order function.
   */
  handlePlaceOrderClick() {
    this.placeOrder();
  }

  render() {
    const {cart} = this.props.storeReducer;
    const {placingOrder} = this.state;
    const {
      selectedBillingAddressId,
      selectedShippingAddressId,
      selectedCreditCardId,
      selectedCVVCode
    } = this.props.checkoutReducer;
    const {addresses, creditCards} = this.props.myAccountReducer;

    const incompleteInfo = selectedCreditCardId === null || !selectedBillingAddressId ||
        !selectedShippingAddressId || !selectedCVVCode;

    let selectedShippingAddress = {};
    let selectedBillingAddress = {};
    let selectedCreditCard = {};
    if (addresses && addresses.length > 0) {

      if (selectedCreditCardId !== 0)
        selectedCreditCard = creditCards.find(creditCard => creditCard.id === selectedCreditCardId);
      else
        selectedCreditCard = null;

      selectedShippingAddress = addresses.find(address => address.id === selectedShippingAddressId);
      selectedBillingAddress = addresses.find(address => address.id === selectedBillingAddressId)

      if(!selectedBillingAddress && selectedCreditCard && selectedCreditCard.billing_address)
        selectedBillingAddress = selectedCreditCard.billing_address_data;

    }

    /*
    IMPORTANT: if the cart is not present when rendering the component, then the user
    navigated to this component directly or reloaded the page, and because of that, selected
    addresses and credit card won't be in the store so we don't have the information for the
    checkout end-point. That's why we need to redirect.
     */

    if (!cart || incompleteInfo)
      return <Redirect to="/store/cart"/>;

    return (
        <section id="cart" className="checkout-summary">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Checkout</h1>
                <div className="alert alert-warning" role="alert" style={{textTransform: "none"}}>
                  If purchasing multiple items, you might expect separate deliveries.
                </div>
                <div className="wrapper">
                  <h5 className="bold">Shipping Address</h5>
                  <AddressInfo
                      address={selectedShippingAddress}/>
                  <hr/>
                  <h5 className="bold">Shipping Method</h5>
                  Standard Shipping
                  <hr/>
                  <h5 className="bold">Payment Method</h5>
                  {
                    selectedCreditCard ?
                        <CreditCardInfo
                            creditCard={selectedCreditCard}/>
                        :
                        <PayPalInfo/>
                  }
                  <h5 className="bold">Billing Address</h5>
                  <AddressInfo
                      address={selectedBillingAddress}/>
                </div>
                <div className="wrapper">
                  <h2>
                    ORDER SUMMARY
                    <span className="right">
                      <Link to="/store/cart">Edit</Link>
                    </span>
                  </h2>
                  <div className="cart-summary">
                    {
                      cart.cart_items.map(item =>
                          <CartItemContainer
                              item={item}
                              showActions={false}
                              key={`cartItem${item.id}`}/>
                      )
                    }
                  </div>
                  <hr/>
                  <CartCouponContainer
                      couponBoxStyle="compact"/>
                  <CartTotalsContainer/>
                  {
                    (incompleteInfo || !isAuthenticated()) &&
                    <VintageAlert>
                      {
                        incompleteInfo &&
                        <p>
                          Please make sure you input your shipping and billing address as well as your credit card
                          information before being able to complete checkout process.
                        </p>
                      }
                      {
                        !isAuthenticated() &&
                        <p>
                          If you already have an account, please <Link to={{
                          pathname: '/auth/login',
                          search: `?${FROM}=${this.props.match.path}`
                        }}>login here</Link>.
                        </p>
                      }
                    </VintageAlert>
                  }
                  {
                    this.state.nonFieldErrors.map((error, index) =>
                        <div
                            key={index}
                            className="alert alert-danger"
                            role="alert"
                            style={{textTransform: "none", textAlign: "left"}}>
                          <strong>Error!</strong> {error}
                        </div>
                    )
                  }
                  <div>
                    {
                      selectedCreditCardId === 0 ? (
                          <PaypalButton
                              billing_address={selectedShippingAddressId}
                              shipping_address={selectedShippingAddressId}
                              completedOrder={this.completedOrder}
                              disabled={!selectedShippingAddressId}/>
                      ) : (
                          <button
                              type="button"
                              className="btn btn-block"
                              disabled={placingOrder}
                              onClick={this.handlePlaceOrderClick}>
                            PLACE YOUR ORDER
                          </button>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SEO url='checkout/summary'/>
        </section>
    )
  }
}

export default withRouter(Summary)
