import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import Order from "scenes/MyAccount/scenes/OrderHistory/components/Order/index";
import OrderTotals from "scenes/MyAccount/scenes/OrderDetails/components/OrderTotals/index";
import CreditCardInfo from "utils/components/CreditCardInfo/index";
import AddressInfo from "utils/components/AddressInfo/index";
import {Link} from "react-router-dom";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import "./styles/GuestOrderDetails.scss";

class GuestOrderDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: null
    };

    this.fetchOrder = this.fetchOrder.bind(this);
  }

  componentDidMount() {
    this.fetchOrder();
  }

  /**
   * Fetch order from the API.
   * @returns {AxiosPromise}
   */
  fetchOrder() {
    const {orderUUID} = this.props.match.params;

    const promise = vintageAxios.get(`management/orders/${orderUUID}/`);

    promise.then(response => {
      this.setState({
        order: response.data
      });
    });

    return promise;
  }

  render() {
    const {order} = this.state;

    if (!order)
      return <LoadingCassette/>;

    return (
        <section className="page-content guest-order-details">
          <div className="container">
            <div className="wrapper orders">
              <h1>Order Details</h1>
              <div className="order-item">
                <div className="row">
                  <div className="col-md-12">
                    <Order
                        order={order}
                        showDetailsLink={false}/>
                  </div>
                  <div className="col-md-12">
                    <hr/>
                    <div className="box">
                      <div className="payment-info">
                        <h6>Payment Method:</h6>
                        <CreditCardInfo
                            creditCard={order.credit_card_data}/>
                        <h6>Shipping Method:</h6>
                        <p>Best Way ­Standard</p>
                      </div>
                    </div>
                    <div className="shipping-info">
                      <h6>Shipping to:</h6>
                      <AddressInfo
                          address={order.shipping_address_data}/>
                    </div>
                    <div className="shipping-info">
                      <h6>Billing to:</h6>
                      <AddressInfo
                          address={order.billing_address_data}/>
                    </div>
                    <hr/>
                  </div>
                  <div className="col-md-12">
                    <div className="summary">
                      <h6>Order Summary</h6>
                      <OrderTotals
                          order={order}/>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <hr/>
                    <div className="options">
                      {/*{
                        order.shipments.length > 0 && (
                            <Link to={`/my-account/order-history/${order.uuid}/tracking`}>
                              <span className="btn-custom tracking">TRACKING</span>
                            </Link>
                        )
                      }*/}
                      <Link to={`/my-account/order-history/${order.uuid}/return`}>
                        <span className="btn-custom tracking">RETURN</span>
                      </Link>
                      <Link to={`/my-account/order-history/${order.uuid}/cancel`}>
                        <span className="btn-custom tracking">CANCEL</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

    )
  }
}

export default GuestOrderDetails;
