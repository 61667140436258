import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import {INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS} from "assets/constants/constants";
import SEO from "components/SEO/index";
import vintageHistory from "services/browser-history/index";
import ResetPasswordForm from "scenes/Auth/scenes/ResetPassword/components/ResetPasswordForm/index";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: INITIAL_STATUS,
      errors: []
    };

		this.refRecaptcha = React.createRef();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * Handles the submit form
   * @param {Object} data
   */
  handleFormSubmit(data) {
    this.setState({
      status: LOADING_STATUS
    }, () => {
      // Taken token
      data.uuid_token = this.props.match.params.uuidToken;
      const promise = vintageAxios.post('/auth/reset-password/', {
				...data,
				recaptcha: this.refRecaptcha.current.getValue()
			});
      promise.then(() => {
        this.setState({
          status: SUCCESSFUL_STATUS,
          errors: []
        }, () => {
          setTimeout(() => {
            this.setState({
              status: INITIAL_STATUS
            });
          }, 2000);
          // Redirect to login form
          vintageHistory.push('/auth/login');
        });
      });
      promise.catch((error) => {
				this.refRecaptcha.current.reset();
        this.setState({
          status: INITIAL_STATUS,
          errors: error.response ? error.response.data.non_field_errors : []
        });
      });
    });
  }

  render() {
    const {status, errors} = this.state;

    return (
        <div className="container">
          <div className="wrapper wrapper--bg-auth-form">
            <h1>Reset Password:</h1>
            <ResetPasswordForm
                onSubmit={this.handleFormSubmit}
                status={status}
								refRecaptcha={this.refRecaptcha}
                errors={errors}/>
          </div>
          <SEO url='auth/reset-password'/>
        </div>
    )
  }
}

export default ResetPassword;