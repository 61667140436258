import React, {Component} from 'react';
import ForgotPasswordFormRedux from "scenes/Auth/scenes/ForgotPassword/components/ForgotPasswordForm/index";
import vintageAxios from "services/api/index";
import {Redirect} from "react-router-dom";
import {INITIAL_STATUS, LOADING_STATUS, PATH_FORGOT_PASSWORD, SUCCESSFUL_STATUS} from "assets/constants/constants";
import SEO from "components/SEO/index";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: INITIAL_STATUS,
      errors: []
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.refRecaptcha = React.createRef();
  }

  /**
   * Handles the submit form
   * @param {Object} data
   */
  handleFormSubmit(data) {
    this.setState({
      status: LOADING_STATUS
    }, () => {
      const promise = vintageAxios.post(PATH_FORGOT_PASSWORD, {
				...data,
				recaptcha: this.refRecaptcha.current.getValue()
			});
      promise.then(() => {
        this.setState({
          status: SUCCESSFUL_STATUS
        }, () => {
          setTimeout(() => {
            this.setState({
              status: INITIAL_STATUS,
              errors: []
            });
          }, 2000);
        });
      });
      promise.catch((error) => {
				this.refRecaptcha.current.reset();
        this.setState({
          status: INITIAL_STATUS,
          errors: error.response ? error.response.data.email : []
        });
      });
    });
  }

  render() {
    const {status, errors} = this.state;
    const {isAuthenticated} = this.props;

    if (isAuthenticated)
      return <Redirect to="/my-account"/>;

    return (
        <div className="container">
          <div className="wrapper wrapper--bg-auth-form">
            <p>Please enter your email address below to reset:</p>
            <ForgotPasswordFormRedux
                onSubmit={this.handleFormSubmit}
								refRecaptcha={this.refRecaptcha}
                status={status}
                errors={errors}/>
          </div>
          <SEO url='auth/forgot-password'/>
        </div>
    )
  }
}

export default ForgotPassword;