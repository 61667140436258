const currentYear = new Date().getFullYear() % 100;

export const required = value => value ? undefined : 'This field is required';
export const onlyText = value => value && /[^a-zA-Z ]/i.test(value)
    ? 'Please input a valid value'
    : undefined;
export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Invalid email address'
        : undefined;
export const creditCardNumber = value => !isNaN(value) && (value.length >= 13) ? undefined : 'Please input a valid card number';
export const creditCardMonth = value => !isNaN(value) && value.length <= 2 ? undefined : 'Please input a valid month';
export const creditCardMonthData = value => value >= 1 && value <= 12 ? undefined : 'Months should be between 1 and 12';
export const creditCardYear = value => !isNaN(value) && value.length === 2  ? undefined : 'Please input a valid year';
export const creditCardYearData = value => value >= currentYear ? undefined : `Year must be ${currentYear} or later`;
export const creditCardCvv = value => !isNaN(value) ? undefined : 'Please input a valid cvv code';
export const creditCardCvvData = value => value.length >= 3 && value.length <= 4 ? undefined : 'Please input the 3 or 4 digits of the cvv code';

export const number = value => isNaN(value) || value >= 0 ? undefined : 'Please input a valid value';