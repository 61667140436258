import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {Link} from "react-router-dom";
import {renderInput} from "utils/forms/renderers";
import {email, required} from "utils/forms/validators";
import PropTypes from "prop-types";
import {INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS} from "assets/constants/constants";
import Recaptcha from "components/Recaptcha/index";
import withRecaptcha from "utils/components/withRecaptcha/index";

export class ForgotPasswordForm extends Component {
  render() {
    const {status, handleSubmit, errors, refRecaptcha} = this.props;
		console.log(refRecaptcha);

    return (
        <form className="form form-auth" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email <span className="required">*</span></label>
            <Field id="email"
                   name="email"
                   component={renderInput}
                   type="email"
                   className="form-control"
                   validate={[required, email]}
                   placeholder="Type your email address"
                   autoComplete="email"/>
            {errors && errors.map((error, index) => (
                <span
                    key={`forgotPasswordError${index}`}
                    className="help-block">
                  {error}
                </span>
            ))}
          </div>
          <Recaptcha
							refRecaptcha={refRecaptcha}
              afterCheckedRecaptcha={this.props.afterCheckedRecaptcha}
              onExpiredRecaptcha={this.props.onExpiredRecaptcha}/>
          <br/>
          <div className="form-group">
            <button type="submit"
                    disabled={
                      status === LOADING_STATUS
                      || status === SUCCESSFUL_STATUS
                      || !this.props.checkedRecaptcha}>
              {
                status === LOADING_STATUS ? (
                    <span>SENDING...</span>
                ) : (
                    status === SUCCESSFUL_STATUS ? (
                        <span>
                          <i className="fa fa-check"/>SENT
                        </span>
                    ) : (
                        <span>SEND</span>
                    )
                )
              }
            </button>
          </div>
          <div className="options">
            <p className="text-center">
              <Link to="/auth/login"><span>Login</span></Link>
              <span className="vline"/>&nbsp;|&nbsp;
              <Link to="/auth/signup"><span className="green">Create an Account&nbsp;</span></Link>
            </p>
          </div>
        </form>
    )
  }
}

ForgotPasswordForm.propsTypes = {
  status: PropTypes.oneOf(
      [INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS]
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const ForgotPasswordFormRedux = reduxForm({
  form: 'forgotPassword'
})(ForgotPasswordForm);

export default withRecaptcha(ForgotPasswordFormRedux);