import React, {Component} from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import classNames from "classnames/bind";
import "./styles/CartTotals.scss";
import {getCartSession} from "services/store/index";
import vintageAxios from "services/api/index";
import {isAuthenticated} from "services/auth/index";
import {getStoredAddresses} from "scenes/Store/scenes/Checkout/services/storage/index";
import {DEFAULT_BY_SHIPPING} from "scenes/MyAccount/scenes/AddressBook/components/Address/constants/index";

class CartTotals extends Component {
  componentDidMount() {
    this.fetchAddresses().then(() => this.fetchTotals())
  }

  /**
   * Fetch totals from the API.
   */
  fetchTotals() {
    const {checkoutReducer, zipCode} = this.props;
    let data = {},
        url = `/management/shopping-carts/${getCartSession()}/totals/`;
    if (checkoutReducer.selectedShippingAddressId)
      data["shipping_address"] = checkoutReducer.selectedShippingAddressId;
    if (zipCode)
      url = `/management/shopping-carts/${getCartSession()}/totals/zipcode/${zipCode}/`;
    const promise = vintageAxios.post(url, data);
    promise.then(response => {
      this.props.receiveCartTotals(response.data);
    });
    return promise;
  }

  /**
   * Checks if addresses are already in the store and returns a promise to fetch
   * them from the API if not.
   * @returns {AxiosPromise<any>}
   */
  get addressesPromise() {
    const {addresses} = this.props.myAccountReducer;
    // Addresses already fetched, no need to re fetch them
    if (addresses && addresses.length > 0)
      return Promise.resolve({data: []});

    // Simulate promise returning empty array when user is not authenticated
    const promise = isAuthenticated() ?
        vintageAxios.get('/v2/management/customers/addressess/')
        :
        Promise.resolve({data: getStoredAddresses()});
    return promise;
  }

  /**
   * Fetch addresses from the API.
   * @returns {AxiosPromise<any>}
   */
  fetchAddresses() {
    // TODO get addresses by type (billing, shipping or both) in the Checkout page.
    const promise = this.addressesPromise;
    promise.then((response) => {
      if (!this.props.myAccountReducer.addresses){
        this.props.receiveAddresses(response.data.results);
      }

      const {addresses} = this.props.myAccountReducer;
      if (addresses && addresses.length > 0) {
        const selectedShippingAddress = addresses.filter(address => address.default && address.address_type === DEFAULT_BY_SHIPPING)[0] ||
            // otherwise select the first one
            addresses[0];
        this.props.selectCheckoutShippingAddress(selectedShippingAddress.id);
      }
    });
    return promise;
  }

  debounceFetchTotals = debounce(this.fetchTotals, 500);

  componentDidUpdate(prevProps) {
    if (
        prevProps.storeReducer.cart !== this.props.storeReducer.cart  // Cart items or quantities changed
        || prevProps.shippingAddress !== this.props.shippingAddress  // Shipping address in checkout changed
        || prevProps.zipCode !== this.props.zipCode  // Zip code in cart changed
    )
      this.debounceFetchTotals();
  }

  render() {
    const {cartTotals} = this.props.storeReducer;
    const {cart} = this.props.storeReducer;

    if (!cartTotals.total)  // Object is empty
      return <div>Loading...</div>;

    return (
        <table className={classNames({
          "table shopping-cart-totals": true
        })}>
          <colgroup>
            <col className="col-md-6"/>
            <col className="col-md-6"/>
          </colgroup>
          <tbody>
          <tr>
            <td>Subtotal</td>
            <td className="right">${cartTotals.sub_total}</td>
          </tr>
          {
            (cart.discount_code_data || cartTotals.discount_fee > 0) && (
                <tr className="shopping-cart-totals__discount">
                  <td>Discount {cart.discount_code_data ? cart.discount_code_data.code: ""}</td>
                  <td className="right">- ${cartTotals.discount_fee}</td>
                </tr>
            )
          }
          <tr>
            <td>Shipping</td>
            <td className="right">${cartTotals.shipping_fee}</td>
          </tr>
          {
            cartTotals.promotions_applied.map((discount, index) => (
                <tr
                    key={`extra-discount-${index}`}
                    className="shipping-cart-totals__promotion">
                  <td>{discount.name}</td>
                  <td className="right">-${discount.total}</td>
                </tr>
            ))
          }
          <tr>
            <td>Taxes</td>
            <td className="right">${cartTotals.sale_tax}</td>
          </tr>
          <tr>
            <td>SUB TOTAL</td>
            <td className="right">${cartTotals.sub_total}</td>
          </tr>
          <tr className="total">
            <td>TOTAL</td>
            <td className="right">${cartTotals.total}</td>
          </tr>
          </tbody>
        </table>
    )
  }
}

CartTotals.propTypes = {
  shippingAddress: PropTypes.object,
  zipCode: PropTypes.string
};

CartTotals.defaultPropTypes = {
  shippingAddress: null,
  zipCode: null
};

export default CartTotals
